import { Observable, BehaviorSubject } from "rxjs";
import { Injectable, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { DataAccessHelpersService } from "./data-access-helpers.service";
import { firestore } from "firebase";
import { AngularFireAuth } from "@angular/fire/auth";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ParcAccessService {
  private parcSource = new BehaviorSubject("");
  currentParc = this.parcSource.asObservable();
  API_ENDPOINT = environment.API_ENDPOINT;

  headers = { "Content-Type": "application/json" };

  isLoadingEvent: EventEmitter<any> = new EventEmitter<any>();
  isLoading = false;
  toggleLoading() {
    this.isLoading = !this.isLoading;
    this.isLoadingEvent.emit(this.isLoading);
  }

  getIdToken() {
    return this.afAuth.auth.currentUser.getIdToken(true);
  }

  async deletePlacementTask(parcId, numSerie) {
    const docRef = this.afs
      .collection(`yards/${parcId}/tasks`)
      .ref.where("numSerie", "==", numSerie)
      .where("type", "==", "placement");

    const querySnapshot = await docRef.get();

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      await doc.ref.delete();
    } else {
      console.log("Document not found");
    }
  }

  async deleteUnstackingTask(parcId, numSerie) {
    const docRef = this.afs
      .collection(`yards/${parcId}/tasks`)
      .ref.where("numSerie", "==", numSerie)
      .where("type", "==", "unstacking");

    const querySnapshot = await docRef.get();

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      await doc.ref.delete();
    } else {
      console.log("Document not found");
    }
  }

  async addTask(parcId, taskData) {
    const tasksCollectionRef = this.afs.collection(`yards/${parcId}/tasks`);
    const newTaskRef = await tasksCollectionRef.add(taskData);
    return newTaskRef.id;
  }

  deleteTasks(parcId) {
    const tasksCollectionRef = this.afs.collection(`yards/${parcId}/tasks`).ref;

    const query = tasksCollectionRef.where("type", "in", [
      "placement",
      "sortie",
    ]);

    return query
      .get()
      .then((querySnapshot) => {
        const deletePromises = [];
        querySnapshot.forEach((doc) => {
          deletePromises.push(tasksCollectionRef.doc(doc.id).delete());
        });

        return Promise.all(deletePromises);
      })
      .catch((error) => {
        console.error("Error getting or deleting documents:", error);
        throw error;
      });
  }

  changeParc(parc: any) {
    this.parcSource.next(parc);
  }

  getMostUsedCodes(parc: string) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getTopCodes/?parc=${parc}`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "GET",
        });
      })
      .catch((err) => Promise.reject(err));
  }

  getCredentials(parc: string) {
    return this.dhs.getDocumentOnce(`externalCredentials/${parc}`);
  }

  getAllCredentials() {
    return this.dhs.getCollectionOnce("externalCredentials");
  }

  sendCredentials(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/setupCredentials`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  getOdooAPI(parc: string, type: string) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(
          `${this.API_ENDPOINT}/getOdooAPI?parcId=${parc}&type=${type}`,
          {
            headers: { ...this.headers, Authorization: idToken },
            method: "GET",
          },
        );
      })
      .catch((err) => Promise.reject(err));
  }

  setOdooAPI(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/setOdooAPI`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  sendMove(payload) {
    // console.log("payload", payload);

    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/moves`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  sendInterchange(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/sendInterchange`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  sendCodecoCargomatic(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/sendCodecoCargomatic`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  saveLog(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/logs`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  getInspectionTaskBySN(parcId: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parcId}/tasks`)
      .ref.where("numSerie", "==", numSerie)
      .where("type", "==", "inspection")
      .get();
  }

  getRepairTaskBySN(parcId: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parcId}/tasks`)
      .ref.where("numSerie", "==", numSerie)
      .where("type", "==", "repair")
      .limit(1)
      .get();
  }
  getCustomersOnce() {
    return this.dhs.getCollectionOnce("customers");
  }

  getCustomers() {
    return this.dhs.getCollection("customers");
  }

  getAllParcs(): Promise<IParc[]> {
    return this.dhs.getCollectionOnce("yards");
  }

  getYardOnceById(id: string): Promise<IParc> {
    return this.dhs.getDocumentOnce(`yards/${id}`);
  }

  getYardPostionDictionary(): Promise<IParc> {
    return this.dhs.getDocumentOnce(`constants/yard_position_Alexander1_TIW_AMZN`);
  }


  getYardsByIds(ids: string[]): Promise<IParc[]> {
    const yardPromises: Promise<IParc>[] = ids.map((id) =>
      this.dhs.getDocumentOnce(`yards/${id}`),
    );
    return Promise.all(yardPromises);
  }

  getYardSubscribe(id: string): Observable<IParc> {
    return this.dhs.getDocument(`yards/${id}`);
  }

  setYardFournisseurs(
    parc: string,
    fournId: string,
    data: Partial<IFournisseur>,
  ) {
    return this.afs
      .collection(`yards/${parc}/fournisseurs`)
      .doc(fournId)
      .set(data);
  }

  setYardClients(parc: string, clientId: string, data: Partial<IClient>) {
    return this.afs.collection(`yards/${parc}/clients`).doc(clientId).set(data);
  }

  getInterchangeDocumentsDone(parc: string): Observable<IInterchange[]> {
    return this.dhs.getCollectionWhereByOrderWithLimit(
      `yards/${parc}/interchanges`,
      [{ left: "printable", operator: "==", right: true }],
      "date",
      "desc",
      3000,
    );
  }

  getAllAppointments(id: string) {
    return this.dhs.getCollectionOnce(`yards/${id}/truckerBookings`);
  }

  getSubAppointments(id: string): Observable<any[]> {
    return this.dhs.getCollectionWhereByOrderWithLimit(
      `yards/${id}/truckerBookings`,
      [],
      "date",
      "desc",
      2000,
    );
    // return this.dhs.getCollection(`yards/${id}/truckerBookings`);
  }

  getLastReservedPiece(parcId: string, refPiece: string) {
    return this.afs
      .collection(`yards/${parcId}/spare-parts-inventory`)
      .ref.where("refPiece", "==", refPiece)
      .orderBy("lastIncrement", "desc")
      .get();
  }

  getInterchangeByNumSerie(parc: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parc}/interchanges`)
      .ref.where("numSerie", "==", numSerie)
      .get();
  }

  getLatestInterchangeByNumSerie(parc: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parc}/interchanges`)
      .ref.where("numSerie", "==", numSerie)
      .orderBy("date", "desc")
      .limit(1)
      .get();
  }

  getDevisByNumSerie(parc: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parc}/devis`)
      .ref.where("numSerie", "==", numSerie)
      .get();
  }

  setOrder(parc: string, order) {
    return this.afs.collection(`yards/${parc}/orders`).add(order);
  }

  getAllOrders(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/orders`);
  }

  getAllBills(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/devis`);
  }

  getOrders(parc: string) {
    return this.dhs.getCollectionOrderBy(
      `yards/${parc}/orders`,
      "createdAt",
      "desc",
    );
  }

  getYardClients(parc: string): Observable<IClient[]> {
    return this.dhs.getCollection(`yards/${parc}/clients`);
  }

  getYardsAllClients(parcs: string[]): Promise<any[][]> {
    const clients = parcs.map((id) =>
      this.dhs.getCollectionOnce(`yards/${id}/clients`),
    );
    return Promise.all(clients);
  }

  getYardClientsOnce(parc: string): Promise<IClient[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/clients`);
  }

  getInterchangeDocumentsPending(parc: string): Observable<IInterchange[]> {
    return this.dhs.getCollectionWhere(`yards/${parc}/interchanges`, [
      { left: "printable", operator: "==", right: false },
    ]);
  }

  getIncompletedGatesOnce(parc: string): Promise<any[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/incompleteGate`);
  }
  getIncompletedGates(parc: string): Observable<any[]> {
    return this.dhs.getCollection(`yards/${parc}/incompleteGate`);
  }

  getYardFournisseur(parc: string): Observable<IFournisseur[]> {
    return this.dhs.getCollection(`yards/${parc}/fournisseurs`);
  }
  getAllTransportersOnce(parc: string): Promise<any[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/fournisseurs`);
  }
  getYardFournisseurOnce(parc: string, fournId: string): Promise<any> {
    return this.dhs.getDocumentOnce(`yards/${parc}/fournisseurs/${fournId}`);
  }
  getTruckingCompanyByPlate(parc: string, plate: string) {
    return this.afs
      .collection("yards")
      .doc(parc)
      .collection("fournisseurs")
      .ref.where("trucks", "array-contains", plate)
      .limit(1)
      .get();
  }
  getTruckingCompanyByParc(parc: string) {
    return this.afs
      .collection("yards")
      .doc(parc)
      .collection("fournisseurs")

      .get();
  }
  deleteYardFournisseur_Client(path: string) {
    return this.afs.doc(path).delete();
  }
  deleteContract(path: string) {
    return this.afs.doc(path).delete();
  }
  updateYardFournisseur(path: string, data: Partial<IFournisseur>) {
    return this.afs.doc(path).update(data);
  }
  updateYardClient(parc: string, id: string, data: Partial<IClient>) {
    return this.afs.doc(`yards/${parc}/clients/${id}`).update(data);
  }
  setYardData(parc: string, data: Partial<IParc>) {
    return this.afs.doc(`yards/${parc}`).update(data);
  }

  postContainer(parc: string, numSerie: string, data: IContainer) {
    return this.afs
      .collection(`yards/${parc}/containers`)
      .doc(`${numSerie}`)
      .set(data);
  }

  updateOnwheels(parc: string, numSerie: string, Etat: string) {
    return this.afs
      .collection(`yards/${parc}/containers`)
      .doc(`${numSerie}`)
      .update({
        chassisNumber: null,
        position: null,
        etat: Etat,
      });
    //.update({ chassisNumber: null })
  }

  updatecontainers(parc: string, numSerie: string, numChassisValue) {
    return this.afs
      .collection(`yards/${parc}/containers`)
      .doc(`${numSerie}`)
      .update({
        chassisNumber: numChassisValue,
        position: null,
      });
    //.update({ chassisNumber: null })
  }

  updateChassis(parc: string, numSerie: string, Etat: string) {
    return this.afs
      .collection(`yards/${parc}/chassis`)
      .doc(`${numSerie}`)
      .update({
        withContainerNumber: null,
        etat: Etat,
        numPlomb: null,
        emptyFull: false,
      });
  }

  updateChassisTask(parc: string, numSerie: string) {
    const tasksRef = this.afs.collection(`yards/${parc}/tasks`, (ref) =>
      ref.where("numSerie", "==", numSerie),
    );

    return tasksRef
      .get()
      .toPromise()
      .then((querySnapshot) => {
        const batch = this.afs.firestore.batch();
        querySnapshot.forEach((doc) => {
          batch.update(doc.ref, { relatedToOnwheels: false });
        });
        return batch.commit();
      });
  }

  updateCTRTask(parc: string, numSerie: string) {
    const tasksRef = this.afs.collection(`yards/${parc}/tasks`, (ref) =>
      ref.where("numSerie", "==", numSerie),
    );

    return tasksRef
      .get()
      .toPromise()
      .then((querySnapshot) => {
        const batch = this.afs.firestore.batch();
        querySnapshot.forEach((doc) => {
          batch.update(doc.ref, { onWheels: false });
        });
        return batch.commit();
      });
  }

  updateContainerInfoTask(parc: string, numSerie: string, infos: string) {
    const tasksRef = this.afs.collection(`yards/${parc}/tasks`, (ref) =>
      ref.where("numSerie", "==", numSerie),
    );

    return tasksRef
      .get()
      .toPromise()
      .then((querySnapshot) => {
        const batch = this.afs.firestore.batch();
        querySnapshot.forEach((doc) => {
          batch.update(doc.ref, { infos: infos });
        });
        return batch.commit();
      });
  }

  updateChassiswithContainerNumber(
    parc: string,
    numChassis: string,
    numSerie: string,
  ) {
    return this.afs
      .collection(`yards/${parc}/chassis`)
      .doc(`${numChassis}`)
      .update({ withContainerNumber: [numSerie], position: null });
  }

  // updateChassisPosition(parc: string, numSerie: string) {
  //   return this.afs
  //     .collection(`yards/${parc}/chassis`)
  //     .doc(`${numSerie}`)
  //     .update({ position: null });
  // }

  postTask(parc: string, data: Partial<ITask>) {
    return this.afs.doc(`yards/${parc}`).collection("tasks").add(data);
  }

  postTaskwithID(parc: string, id: string, data: Partial<ITask>) {
    return this.afs.doc(`yards/${parc}`).collection("tasks").doc(id).set(data);
  }

  setStackingRule(parcId: string, bayId: string, data) {
    return this.afs
      .collection(`yardPlans/${parcId}/stackingRules`)
      .doc(bayId)
      .set(data);
  }

  deleteStackingRule(parcId: string, bayId: string) {
    return this.afs.doc(`yardPlans/${parcId}/stackingRules/${bayId}`).delete();
  }

  getStackingRule(parcId: string, bayId: string) {
    return this.dhs.getDocumentOnce(
      `yardPlans/${parcId}/stackingRules/${bayId}`,
    );
  }

  getPendingBays(parc: string) {
    return this.dhs.getCollectionOnce(`yards/${parc}/pendingBays`);
  }

  getStackingRules(parcId: string) {
    return this.dhs.getCollectionOnce(`yardPlans/${parcId}/stackingRules`);
  }

  updateStackingRule(parcId: string, bayId: string, data) {
    return this.afs
      .doc(`yardPlans/${parcId}/stackingRules/${bayId}`)
      .update(data);
  }

  postInterchange(parc: string, data: Partial<IInterchange>, taskId: string) {
    return this.afs
      .doc(`yards/${parc}`)
      .collection("interchanges")
      .doc(taskId)
      .set(data);
  }

  getYardPlanOnce(yardId: string): Promise<IPlan> {
    return this.dhs.getDocumentOnce(`/yardPlans/${yardId}`);
  }

  getYardPlan(yardId: string): Observable<IPlan> {
    return this.dhs.getDocument(`yardPlans/${yardId}`);
  }

  updateYardPlan(yardId: string, plan: Partial<IPlan>) {
    return this.afs.doc(`/yardPlans/${yardId}`).update(plan);
  }

  autoCompleteForm(parc: string) {
    return this.afs
      .collection(`/yards/${parc}/tasks`)
      .ref.where("type", "==", "gateFromOCR")
      .where("inProgress", "==", false)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
  }
  setCurrencyRate(id, data) {
    return this.afs.collection("exchangeRates").doc(id).set(data);
  }
  updateCurrencyRate(id, data) {
    return this.afs.collection("exchangeRates").doc(id).update(data);
  }
  deleteCurrencyRate(id) {
    return this.afs.collection("exchangeRates").doc(id).delete();
  }
  getCurrencyRates() {
    return this.dhs.getCollection("exchangeRates");
  }
  getCurrencyRate(id) {
    return this.dhs.getDocumentOnce(`exchangeRates/${id}`);
  }

  updateOCRTask(parc: string, docId: string, data: Partial<ITask>) {
    return this.afs.doc(`yards/${parc}/tasks/${docId}`).update(data);
  }

  deleteTask(parc: string, docId: string) {
    return this.afs.doc(`yards/${parc}/tasks/${docId}`).delete();
  }

  deleteDevis(parc: string, docId: string) {
    return this.afs.doc(`yards/${parc}/devis/${docId}`).delete();
  }

  deleteContainer(parc: string, numSerie: string) {
    return this.afs.doc(`yards/${parc}/containers/${numSerie}`).delete();
  }

  getPlacementTasks(parc: string): Observable<ITask[]> {
    return this.dhs.getCollectionWhere(`yards/${parc}/tasks`, [
      { left: "type", operator: "in", right: ["placement"] },
    ]);
  }

  getPlacementTasksOnce(parc: string) {
    return this.afs
      .collection(`yards/${parc}/tasks`)
      .ref.where("type", "==", "placement")
      .get();
  }

  getStockInventroyByLastDec(parc: string): Observable<any[]> {
    return this.dhs.getCollectionOrderBy(
      `yards/${parc}/spare-parts-inventory`,
      "lastDecrement",
      "desc",
    );
  }
  getTaskByPrenotifRef(parc: string, prenotifId: string) {
    return this.afs
      .collection(`yards/${parc}/tasks`)
      .ref.where("prenotifId", "==", prenotifId)
      .get();
  }
  deleteTaskByPrenotifRef(parc: string, docId: string) {
    return this.afs.doc(`yards/${parc}/tasks/${docId}`).delete();
  }
  getStockInventroy(parc: string): Observable<any[]> {
    return this.dhs.getCollection(`yards/${parc}/spare-parts-inventory`);
  }

  getStockInventroyOnce(parc: string): Promise<any[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/spare-parts-inventory`);
  }

  getRelatedTasks(yardId, billId) {
    return this.afs
      .collection("yards")
      .doc(yardId)
      .collection("tasks")
      .ref.where("estimateId", "==", billId)
      .get();
  }
  getPiece(parc, refP) {
    return this.afs
      .collection(`yards/${parc}/spare-parts-inventory`)
      .ref.where("refPiece", "==", refP)
      .orderBy("integrationTime")
      .get();
  }
  getPendingTasks(parc, billId) {
    return this.afs
      .collection(`yards/${parc}/pendingTasks`)
      .ref.where("estimateId", "==", billId)
      .get();
  }
  getFinishedMachineryRepairs(parcId) {
    return (
      this.afs
        .collection(`yards/${parcId}/devis`)
        .ref.where("type", "==", "devisM")
        //.where("reparationReef", ">", 0)
        .where("reefRepairDate", ">", 0)
        .where("status", "==", "pending")
        .get()
    );
  }
  getFinishedBoxRepairs(parcId) {
    return (
      this.afs
        .collection(`yards/${parcId}/devis`)
        .ref.where("type", "==", "devis")
        //.where("reparationBox", ">", 0)
        .where("boxRepairDate", ">", 0)
        .where("status", "==", "pending")
        .get()
    );
  }
  getPieceInRepairTask(parcId) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("tasks")
      .ref.where("type", "==", "repair")
      .get();
  }
  getPieceByVendor(parc, refP, vendor, stockUnit?) {
    return (
      this.afs
        .collection(`yards/${parc}/spare-parts-inventory`)
        .ref.where("refPiece", "==", refP)
        .where("vendor", "in", [vendor, ""])
        //.where("stockUnit",'==',stockUnit)
        .orderBy("integrationTime")
        .limit(1)
        .get()
    );
  }
  getOutOfStockParts(parc) {
    return this.afs
      .collection(`yards/${parc}/spare-parts-inventory`)
      .ref.where("availableQuantity", "<=", 0)
      .get();
  }

  setPiece(parc, data) {
    return this.afs.collection(`yards/${parc}/spare-parts-inventory`).add(data);
  }
  decrementStock(path, decr) {
    const decrement = firestore.FieldValue.increment(decr * -1);
    return this.afs.doc(path).update({ availableQuantity: decrement });
  }
  getAllTasks(parc: string): Promise<ITask[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/tasks`);
  }

  setBooking(parc: string, id: string, data: Partial<any>) {
    return this.afs.collection(`yards/${parc}/booking`).doc(id).set(data);
  }
  getBooking(parc: string): Observable<IBooking[]> {
    return this.dhs.getCollectionOrderBy(
      `yards/${parc}/booking`,
      "createdAt",
      "desc",
    );
  }
  getBookingOnce(parc: string): Promise<IBooking[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/booking`);
  }
  getSpecificBooking(parc: string, idBooking: string) {
    return this.dhs.getDocumentOnce(`yards/${parc}/booking/${idBooking}`);
  }
  deleteBooking(parc: string, id: string) {
    return this.afs.doc(`yards/${parc}/booking/${id}`).delete();
  }
  updateBooking(parc: string, id: string, data: Partial<IBooking>) {
    return this.afs.doc(`yards/${parc}/booking/${id}`).update(data);
  }
  deleteReport(path: string) {
    return this.afs.doc(path).delete();
  }
  updateReport(path: string, data: Partial<IAuthorizedReports>) {
    return this.afs.doc(path).update(data);
  }
  setReport(parc: string, data: IAuthorizedReports) {
    return this.afs.collection(`yards/${parc}/reports`).add(data);
  }
  getReports(parc: string): Observable<IAuthorizedReports[]> {
    return this.dhs.getCollection(`yards/${parc}/reports`);
  }
  getReportsOnce(parc: string): Promise<IAuthorizedReports[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/reports`);
  }
  getDepotsContracts(parc: string, client: string, type: string) {
    return this.dhs.getCollection(`yards/${parc}/clients/${client}/${type}`);
  }
  deleteDevisVente(parc: string, id: string) {
    return this.afs.doc(`yards/${parc}/devis_vente/${id}`).delete();
  }

  checkContract(
    parc: string,
    client: string,
    type: string,
    gateType?: string,
    codeIso?: string,
    conSize?: string,
    conType?: string,
    emptyFull?: boolean,
  ) {
    /* /yards/ASCOT-DEV/clients/CMA/storageContracts */
    return this.afs
      .collection(`yards/${parc}/clients/${client}/${type}`)
      .ref.where("gateType", "==", gateType)
      .where("codeIso", "==", codeIso)
      .where("conSize", "==", conSize)
      .where("conType", "==", conType)
      .where("emptyFull", "==", emptyFull)
      .get();
  }

  checkCFSContract(
    parc: string,
    client: string,
    contract: string,
    type: string,
  ) {
    return this.afs
      .collection(`yards/${parc}/clients/${client}/${contract}`)
      .ref.where("type", "==", type)
      .get();
  }

  getDepotsContractsOnce(
    parc: string,
    client: string,
    type: string,
  ): Promise<any[]> {
    return this.dhs.getCollectionOnce(
      `yards/${parc}/clients/${client}/${type}`,
    );
  }
  setDepotsContracts(
    parc: string,
    client: string,
    type: string,
    data: Partial<IStorageContract | IShuntingContract | IHandlingContract>,
  ) {
    return this.afs
      .collection(`yards/${parc}/clients/${client}/${type}`)
      .add(data);
  }
  setShuntingContracts(
    parc: string,
    client: string,
    data: Partial<IShuntingContract>,
  ) {
    return this.afs
      .collection(`yards/${parc}/clients/${client}/shuntingContracts`)
      .add(data);
  }
  setBundlingContracts(
    parc: string,
    client: string,
    data: Partial<IBundlingContract>,
  ) {
    return this.afs
      .collection(`yards/${parc}/clients/${client}/bundlingContracts`)
      .add(data);
  }

  setCFSContracts(parc: string, client: string, data: Partial<ICFSContract>) {
    return this.afs
      .collection(`yards/${parc}/clients/${client}/CFSContracts`)
      .add(data);
  }
  updateDepotsContract(
    path: string,
    data: Partial<IShuntingContract | IHandlingContract | IStorageContract>,
  ) {
    return this.afs.doc(path).set(data);
  }
  setLogsDepotsContract(parc: string, data: Partial<any>) {
    return this.afs.collection(`yards/${parc}/logs`).add(data);
  }
  getLogsDepotsContract(parc): Observable<any> {
    return this.dhs.getCollection(`yards/${parc}/logs`);
  }
  setLogsUsers(parc: string, data: Partial<any>) {
    return this.afs.collection(`yards/${parc}/logs`).add(data);
  }
  setLogsParcParams(parc: string, data: Partial<any>) {
    return this.afs.collection(`yards/${parc}/logs`).add(data);
  }

  getPreNotifs(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/prenotif`);
  }

  getPreNotifDetails(parcId: string, preNotifId: string) {
    return this.dhs.getDocumentOnce(`yards/${parcId}/prenotif/${preNotifId}`);
  }

  getPrenotifDetails(parcId: string, id: string) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("subPrenotifs")
      .ref.where("prenotifId", "==", id)
      .get();
  }
  updatePreNotifDetailByPath(path: string, data) {
    return this.afs.doc(path).update(data);
  }
  getPreNotifRT(parcId: string, operator: string, type: string | string[]) {
    return this.dhs.getCollectionWhereByOrder(
      `yards/${parcId}/prenotif`,
      [{ left: "type", operator, right: type }],
      "createdAt",
      "desc",
    );
  }
  getBillablePreNotifRT(parcId: string) {
    return this.dhs.getCollectionWhere(`yards/${parcId}/prenotif`, [
      { left: "type", operator: "in", right: ["lavage", "PTI"] },
    ]);
  }
  getSubPrenotifs(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/subPrenotifs`);
  }
  getPosTasks(parcId: string) {
    return this.dhs.getCollectionWhere(`yards/${parcId}/tasks`, [
      { left: "type", operator: "==", right: "Positioning" },
    ]);
  }

  searchForMatchingPrenotif(parcId: string, data) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("subPrenotifs")
      .ref.where("conType", "==", data.conType)
      .where("conSize", "==", data.conSize)
      .where("transporter", "==", data.transporter.id)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
  }

  deletePrenotifByNumSerie(parcId: string, numSerie: string) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("subPrenotifs")
      .ref.where("numSerie", "==", numSerie)
      .where("type", "==", "preAdvice")
      .get()
      .then((querySnapshot) => {
        const batch = this.afs.firestore.batch();
        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
  }

  async getPreNotifId(parcId: string, numSerie: string) {
    const querySnapshot = await this.afs
      .collection("yards")
      .doc(parcId)
      .collection("subPrenotifs")
      .ref.where("numSerie", "==", numSerie)
      .where("type", "==", "preAdvice")
      .get();

    if (!querySnapshot.empty) {
      const results = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      console.log("Fetched prenotif data:", results);
      return results[0];
    } else {
      return null;
    }
  }

  async getPreNotifIdChassis(parcId: string, numSerie: string) {
    const querySnapshot = await this.afs
      .collection("yards")
      .doc(parcId)
      .collection("subPrenotifs")
      .ref.where("chassisNumber", "==", numSerie)
      .where("type", "==", "preAdvice")
      .get();
    if (!querySnapshot.empty) {
      const results = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      console.log("Fetched prenotif data chassis :", results);
      return results[0]; // Return the data of the first document
    } else {
      console.log("No prenotif found for the given criteria.");
      return null; // Return undefined instead of throwing an error
    }
  }

  async removePrenotifRef(prenotifId: string, parcId: string, id: string) {
    try {
      // Fetch the prenotif document
      const snapshot = await this.afs
        .collection("yards")
        .doc(parcId)
        .collection("prenotif")
        .doc(id)
        .get()
        .toPromise();

      if (snapshot.exists) {
        const data = snapshot.data();
        const refs = data.refs || [];
        const total = data.total || 0;
        const progression = data.progression || 0;

        // Filter out the reference object where the path contains prenotifId
        const updatedRefs = refs.filter((ref) => {
          if (ref && ref.path) {
            const pathParts = ref.path.split("/");
            const pathId = pathParts[pathParts.length - 1];
            return pathId !== prenotifId;
          }
          return true;
        });

        if (updatedRefs.length === refs.length) {
          console.log("No reference was removed.");
          return updatedRefs; // Return the current refs if none was removed
        }

        if (updatedRefs.length === 0) {
          // If refs array is empty, delete the entire document
          await this.afs
            .collection("yards")
            .doc(parcId)
            .collection("prenotif")
            .doc(id)
            .delete();

          console.log("Prenotif document deleted as refs array is empty");
        } else {
          // Otherwise, just update the refs array and decrement total and progression
          await this.afs
            .collection("yards")
            .doc(parcId)
            .collection("prenotif")
            .doc(id)
            .update({
              refs: updatedRefs,
              total: total - 1, // Decrement total by 1
              progression: progression - 1, // Decrement progression by 1
            });

          console.log("Element removed successfully, refs updated");
        }

        return updatedRefs; // Return the updated list of refs
      } else {
        console.log("No document found with ID:", id);
        return null; // Handle the case where the document doesn't exist
      }
    } catch (err) {
      console.error("Error removing elements:", err);
      return null; // Return null in case of error
    }
  }

  getCounter(parcId: string, counterName: string) {
    return this.dhs.getDocument(`yards/${parcId}/counters/${counterName}`);
  }

  setPreNotif(parcId: string, id: string, data: Partial<IPreNotif>) {
    return this.afs.collection(`yards/${parcId}/prenotif`).doc(id).set(data);
  }
  setPreNotifDetail(
    parcId: string,
    preNotifId: string,
    containerId: string,
    data: any,
  ) {
    return this.afs
      .collection(`yards/${parcId}/prenotif`)
      .doc(preNotifId)
      .collection("done")
      .doc(containerId)
      .set(data);
  }

  getPreNotifPreAdvice(parcId: string) {
    return this.dhs.getCollectionWhere(`yards/${parcId}/prenotif`, [
      { left: "type", operator: "==", right: "preAdvice" },
    ]);
  }

  updatePreNotif(parcId: string, docId: string, data) {
    return this.afs.doc(`yards/${parcId}/prenotif/${docId}`).update(data);
  }

  deletePreNotif(parcId: string, docId: string) {
    return this.afs.doc(`yards/${parcId}/prenotif/${docId}`).delete();
  }

  deleteOrder(path: string) {
    return this.afs.doc(path).delete();
  }
  updateOrder(path: string) {
    return this.afs.doc(path).update({ isHidden: true });
  }

  addRole(data: any) {
    return this.afs.collection(`constants`).doc("roles").update(data);
  }

  getRoles() {
    return this.dhs.getDocument(`constants/roles`);
  }
  getRolesOnce() {
    return this.dhs.getDocumentOnce(`constants/roles`);
  }

  getRepportPerUser() {
    return this.dhs.getDocumentOnce(`constants/reportPerUserRole`);
  }

  getAllreports() {
    return this.dhs.getDocumentOnce(`constants/allReports`);
  }
  getAllEmailDomains() {
    return this.dhs.getDocumentOnce(`constants/emailDomains`);
  }

  getAllEstimatesIdForSale() {
    return this.dhs.getDocumentOnce(`constants/Estimates_Sale_Id`);
  }

  async updateEstimateIdForSale(parc, newId) {
    try {
      const docRef = this.afs.collection("constants").doc("Estimates_Sale_Id");
      const res = await docRef.get().toPromise();

      if (res.exists) {
        const docData = res.data();
        if (docData && docData.Version) {
          docData.Version[parc] = newId;

          await docRef.update({
            Version: docData.Version,
          });

          console.log(" Version updated ");
        } else {
          console.log("Version field does not exist!");
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("Error when updateEstimateIdForSale:", error);
    }
  }

  addReport(data: any) {
    return this.afs
      .collection(`constants`)
      .doc("reportPerUserRole")
      .update(data);
  }

  addReportData(data: any) {
    return this.afs.collection(`constants`).doc("allReports").update(data);
  }
  getRepportPeruser() {
    return this.dhs.getDocument(`constants/reportPerUserRole`);
  }

  setCustomer(id: string, data: any) {
    return this.afs.collection(`customers`).doc(id).set(data);
  }

  getCapacities() {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getCapacities`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "GET",
        });
      })
      .catch((err) => Promise.reject(err));
  }

  getCapacitiesByPeriod(parcId, period) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getCapacities/${parcId}/${period}`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "GET",
        });
      })
      .catch((err) => Promise.reject(err));
  }

  updateCustomer(id: string, data: any) {
    return this.afs.collection(`customers`).doc(id).update(data);
  }

  getAllParcsSub() {
    return this.dhs.getCollection(`yards`);
  }

  updateScheduleVbs(parcId, data) {
    return this.afs.collection(`yards`).doc(parcId).update(data);
  }

  updateAppointment(parcId, id, data) {
    return this.afs
      .collection(`yards`)
      .doc(parcId)
      .collection(`truckerBookings`)
      .doc(id)
      .update(data);
  }

  getAppointment(parcId, idBooking) {
    return this.dhs.getDocumentOnce(
      `yards/${parcId}/truckerBookings/${idBooking}`,
    );
  }

  getAppointmentByBookingGateOut(
    parcId,
    bookingGateOut,
    plate,
    truckerCompany,
  ) {
    return this.afs
      .collection(`yards/${parcId}/truckerBookings`)
      .ref.where("bookingId", "==", bookingGateOut)
      .where("plate", "==", plate)
      .where("truckerCompany", "==", truckerCompany)
      .where("action", "==", "pick_up")
      .limit(1)
      .get();
  }
  getClientOnce(parcId, clientId): Promise<IClient> {
    return this.dhs.getDocumentOnce(`yards/${parcId}/clients/${clientId}`);
  }

  getUser(uidUser) {
    return this.dhs.getDocumentOnce(`users/${uidUser}`);
  }
  getSubUser(uidUser) {
    return this.dhs.getDocument(`users/${uidUser}`);
  }
  async getExchangeRatesOnce() {
    return await this.dhs.getCollectionOnce("exchangeRates");
  }

  // *********** pump *************

  async getParcCurrency(parcId) {
    const parcData = await this.getYardOnceById(parcId);
    const parcCurrency = parcData.currency;
    let ISOcurrency = "EUR";

    if (parcCurrency === "Euro") {
      ISOcurrency = "EUR";
    }
    if (parcCurrency === "Dollar") {
      ISOcurrency = "USD";
    }

    return ISOcurrency;
  }

  getPumpCollection(parcId) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/stock_PUMP`);
  }
  async getPumpByRefPiece(parcId, refPiece) {
    const doc = await this.dhs.getDocumentOnce(
      `yards/${parcId}/stock_PUMP/${refPiece}`,
    );

    if (doc.hasOwnProperty("pump")) {
      return {
        pump: doc.pump,
        totalReceivedQty: doc.hasOwnProperty("totalReceivedQty")
          ? doc.totalReceivedQty
          : 0,
      };
    } else {
      return { pump: 0, totalReceivedQty: 0 };
    }
  }

  // *********** pump *************

  // ******** carbon **********
  async saveOrderCarbon(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/saveCarbonFootprint`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }
  // ******** carbon **********
  addIncompleteGate(yard, gate) {
    return this.afs.collection(`yards/${yard}/incompleteGate`).add(gate);
  }

  async getPieceStockQuantityByRef(parcId: string, refPiece: string) {
    let stockQuantity = 0;
    try {
      await this.afs
        .collection(`yards/${parcId}/spare-parts-inventory`)
        .ref.where("refPiece", "==", refPiece)
        .get()
        .then((res) => {
          res.docs.forEach((doc) => {
            stockQuantity += doc.data().stockQuantity;
          });
        });

      return stockQuantity;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  // *************** eval piece price ***************

  async validatePiecesPrice(orderPieces, parcId) {
    try {
      let estimatesWithPriceLowerThanPump = [];
      for (let piece of orderPieces) {
        const destimatesWithMissingPieces = await this.getBillsWithMissingRefs(
          parcId,
          piece.refPiece,
        );

        const pump = await this.getPump(parcId, piece.refPiece);

        for (let estimate of destimatesWithMissingPieces) {
          if (pump) {
            if (estimate.price_piece < pump) {
              estimatesWithPriceLowerThanPump.push({ ...estimate, pump });
            }
          } else {
            const currentPump = piece.buyingPrice / piece.exchangeRate;

            if (estimate.price_piece < currentPump) {
              estimatesWithPriceLowerThanPump.push({
                ...estimate,
                pump: currentPump,
              });
            }
          }
        }
      }
      return estimatesWithPriceLowerThanPump;
    } catch (error) {
      console.log(error);
    }
  }

  async getBillsWithMissingRefs(parcId, refPiece) {
    try {
      let data = [];
      await this.afs
        .collection(`yards/${parcId}/devis`)
        .ref.where("missingPieces", "array-contains", refPiece)
        .orderBy("createdAt", "asc")
        .get()
        .then((res) => {
          res.docs.forEach((doc) => {
            if (
              !doc.data().hasOwnProperty("westimSent") &&
              !doc.data().hasOwnProperty("outYard")
            ) {
              const missingPieces = doc
                .data()
                .codeEntries.filter(
                  (ce) =>
                    ce.hasOwnProperty("spare_parts") &&
                    ce.spare_parts.length &&
                    ce.piece_ref === refPiece,
                )
                .map((ce) => {
                  return {
                    price_piece: ce.price_piece,
                    piece_ref: ce.piece_ref,
                    devise_piece: ce.devise_piece,
                  };
                });

              if (missingPieces.length) {
                data.push({
                  ...missingPieces[0],
                  id: doc.id,
                  numSerie: doc.data().numSerie,
                });
              }
            }
          });
        });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async getPump(parcId, refPiece) {
    return this.afs
      .collection(`yards/${parcId}/stock_PUMP`)
      .doc(refPiece)
      .ref.get()
      .then((res) => {
        if (res.exists) {
          return res.data().pump;
        }
        return null;
      });
  }
  // *************** eval piece price ***************

  //**********Get iso code asn type + size from constants ************ */

  async getMatchingCode() {
    return this.afs
      .collection(`constants`)
      .doc(`isoCodes`)
      .ref.get()
      .then((res) => res.data());
  }

  async getIsoCode() {
    return this.afs
      .collection(`constants`)
      .doc(`isoCodes`)
      .ref.get()
      .then((res) => Object.keys(res.data()));
  }

  async getTypesFomIsoCode() {
    return this.afs
      .collection(`constants`)
      .doc(`isoCodes`)
      .ref.get()
      .then((res) =>
        Array.from(
          new Set(
            Object.values(res.data()).map((item) => {
              return item["type"];
            }),
          ),
        ),
      );
  }

  async getSizeFromIsoCode() {
    return this.afs
      .collection(`constants`)
      .doc(`isoCodes`)
      .ref.get()
      .then((res) =>
        Array.from(
          new Set(
            Object.values(res.data()).map((item) => {
              return item["size"];
            }),
          ),
        ),
      );
  }

  incrementID(id) {
    // Utilisation d'une expression régulière pour capturer la partie alphanumérique et la partie numérique
    const match = id.match(/([A-Z-_]+)(\d+)/);

    if (!match) {
      throw new Error("Le format de l'ID est incorrect");
    }

    const prefix = match[1];
    const number = parseInt(match[2]);

    const newNumber = number + 1;

    const newID = prefix + newNumber.toString().padStart(match[2].length, "0");

    return newID;
  }

  /************************************** */

  constructor(
    private dhs: DataAccessHelpersService,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
  ) {}
}
