import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { DataAccessHelpersService } from "./data-access-helpers.service";

@Injectable({
  providedIn: "root",
})
export class ConteneurAccessService {
  /**
   * Returns all containers belongs to a parc in realtime
   * @param idParc Parc Id
   */
  getAllContainer(idParc: string): Observable<any[]> {
    return this.dhs.getCollection(`yards/${idParc}/containers`);
  }

  /**
   * Returns all chassis belongs to a parc in realtime
   * @param idParc Parc Id
   */
  getAllChassis(idParc: string): Observable<any[]> {
    return this.dhs.getCollection(`yards/${idParc}/chassis`);
  }

  /**
   * Returns all chassis belongs to a parc
   * @param idParc Parc Id
   */

  async getAllChassisOnly(idParc: string): Promise<IChassis[]> {
    try {
      const allChassis = await this.dhs.getCollectionOnce(
        `yards/${idParc}/chassis`
      );

      const filteredChassis = allChassis.filter((chassis) => {
        return (
          chassis.withContainerNumber === null ||
          chassis.withContainerNumber.length === 0
        );
      });
      return filteredChassis;
    } catch (error) {
      console.error("Error fetching or filtering chassis only ", error);
      throw error;
    }
  }

  /**
   * Returns all chassis that belong to a parc
   * @param idParc Parc Id
   */
  async getAllOnWheels(idParc: string): Promise<IContainer[]> {
    try {
      // Fetch the collection of containers from the specified parc
      const allCtr: IContainer[] = await this.dhs.getCollectionOnce(
        `yards/${idParc}/containers`
      );

      // Filter out containers where chassisNumber is either null or an empty string
      const filteredCtr = allCtr.filter(
        (ctr) => ctr.chassisNumber && ctr.chassisNumber.length > 0
      );

      return filteredCtr;
    } catch (error) {
      console.error("Error fetching or filtering OnWheels:", error);
      throw error;
    }
  }

  /**
   * Returns all containers belongs to a parc
   * @param idParc Parc Id
   */
  getAllCtr(idParc: string): Promise<IContainer[]> {
    return this.dhs.getCollectionOnce(`yards/${idParc}/containers`);
  }

  /**
   * Returns all containers belonging to a parc
   * @param idParc Parc Id
   */
  async getOnlyCtr(idParc: string): Promise<IContainer[]> {
    // Wait for the promise to resolve
    const allCtr = await this.dhs.getCollectionOnce(
      `yards/${idParc}/containers`
    );

    // Filter the resolved array
    const filteredCtr = allCtr.filter(
      (chassis) => chassis.chassisNumber == null || chassis.chassisNumber < 0
    );

    return filteredCtr;
  }

  /**
   * Returns all subprenotif belongs to a parc
   * @param idParc Parc Id
   */
  getSubPrenotifs(idParc: string): Promise<IContainer[]> {
    return this.dhs.getCollectionOnce(`yards/${idParc}/subPrenotifs`);
  }
  /**
   * Returns all chassis belongs to a parc
   * @param idParc Parc Id
   */
  getAllChass(idParc: string): Promise<IContainer[]> {
    return this.dhs.getCollectionOnce(`yards/${idParc}/chassis`);
  }

  getSpecificCtr(idParc: string, numSerie: string) {
    return this.afs.doc(`yards/${idParc}/containers/${numSerie}`).ref.get();
  }
  /**
   * Returns container data by serial number
   * @param idParc Parc Id
   * @param numSerie Container serial number
   */
  getContainerData(idParc: string, numSerie: string): Promise<IContainer> {
    
    return this.dhs.getDocumentOnce(`yards/${idParc}/containers/${numSerie}`);
  }

  getChassisData(idParc: string, numSerie: string): Promise<IContainer> {
    return this.dhs.getDocumentOnce(`yards/${idParc}/chassis/${numSerie}`);
  }
  updateContainer(idParc: string, numSerie: string, data) {
    return this.afs.doc(`yards/${idParc}/containers/${numSerie}`).update(data);
  }
  getAllClients(idParc: string): Promise<IContainer[]> {
    return this.dhs.getCollectionOnce(`yards/${idParc}/clients`);
  }
  getClientsWithPtiValidity = async (parc: string) => {
    try {
      const clients = await this.db.firestore
        .collection(`yards/${parc}/clients`)
        .get();
      let dic = {};
      clients.docs.forEach((doc) => {
        if (doc.data().ptiValidity) {
          dic = { ...dic, [doc.id]: doc.data().ptiValidity };
        }
      });
      return [dic, Object.keys(dic).length];
    } catch (error) {
      console.log("Something bad happend here", error);
    }
  };

  constructor(
    private dhs: DataAccessHelpersService,
    private afs: AngularFirestore,
    private db: AngularFirestore
  ) {}
}
